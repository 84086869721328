const $win = $(window);
const $doc = $(document);

/**
 * Equalize Height
 */

 $.fn.equalizeHeight = function() {
	var maxHeight = 0, itemHeight;

	this.height('');

	for (var i = 0; i < this.length; i++) {
		itemHeight = $(this[i]).height('auto').height();
		if (maxHeight < itemHeight) {
			maxHeight = itemHeight;
		}
	}

	return this.height(maxHeight);
};

$win.on('load resize', function() {
	$('.materials').each(function(index, element) {
		$(element).find($('.material__head')).equalizeHeight();
	})
});

$('.btn-menu').on('click', function (event) {
 	event.preventDefault();
 	$(this).toggleClass('active');
 	$('.wrapper').toggleClass('nav-active');
  });
